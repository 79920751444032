import './App.css';
import Site from "./Site";
import User from "./User";


function App() {

  const app_title = 'React Based Gadget'

  return (
    <div className="App">
        <header className="App-header">
          <div className="App-container">
              <div className="App-title">{app_title}</div>
              <div className="App-body">
                  <Site></Site>
                  <User></User>
              </div>
          </div>
        </header>
    </div>
  );

}

export default App;
